<template>
  <div class="treaty">
    <div class="topImg">
      <img src="./img/treaty.png" alt="" />
    </div>
    <div class="body">
      <div class="in_body">
        <div
          class="no_content"
          v-if="detail.publishStatus == 0 || !detail.publishStatus"
        >
          <img src="./img/no_content.png" alt="" />
          <div class="message">暂无内容</div>
        </div>
        <div class="body-center">
          <div class="body-center-title">
            <h2>{{ detail.neighborhoodName }}</h2>
          </div>
          <div class="body-c" v-html="detail.neighborhoodText"></div>
        </div>
      </div>
    </div>
    <div class="body-b">
      <div class="btn">
        <div
          class="btn_box"
          v-if="state == 0 && detail.publishStatus == 1"
          @click="toPath('signNeighborhoodPact')"
        >
          签署
        </div>
        <div
          class="btn_box_done"
          v-else-if="state == 1 && detail.publishStatus == 1"
        >
          已签署
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRegister, gloabalCount } from "@/utils/common.js";
import {
  signingOfContractDetailsUrl,
  getNeighborhoodConventionUrl,
} from "./api.js";
export default {
  name: "introduce",
  data() {
    return {
      state: null,
      detail: {
        neighborhoodName: "",
        neighborhoodText: "",
        publishStatus: null,
      },
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    communityId() {
      return this.$store.state.communityId;
    },
  },
  async mounted() {
    if (await toRegister(this.$route.path, this.$route.query, "邻里公约")) {
      this.signOrNot();
      this.getContent();
    }
    gloabalCount("", 71, 1);
  },
  methods: {
    toPath(name) {
      this.$router.push({
        name: name,
      });
    },
    getContent() {
      //获取合约内容
      let params = {
        houseId: this.communityId,
      };
      this.$axios
        .get(`${getNeighborhoodConventionUrl}`, {
          params: params,
        })
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
            if (res.data.publishStatus === 1) {
              this.detail.neighborhoodName = res.data.neighborhoodName;
              this.detail.neighborhoodText = res.data.neighborhoodText;
              this.detail.publishStatus = res.data.publishStatus;
            } else if (res.data.publishStatus === 0) {
              this.detail.publishStatus = res.data.publishStatus;
            }
          }
        });
    },
    signOrNot() {
      //用户是否签约
      let params = {
        userId: this.userId,
      };
      this.$axios
        .get(`${signingOfContractDetailsUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200 || res.code === 400) {
            if (res.msg == "已签署公约！") {
              this.state = 1;
            } else if (res.msg == "未签署公约！") {
              this.state = 0;
            }
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.treaty {
  overflow-y: hidden;
  min-height: 100vh;
  padding: 0 0 68px;
  background: #fb8133;
  box-sizing: border-box;

  .body-b {
    height: 66px;
    width: 100%;
    padding: 0 32px;
    box-sizing: border-box;
    margin: 0 auto;
    position: fixed;
    z-index: 10000;
    bottom: 100px;

    .btn {
      bottom: 0;
      width: 100%;
      // background-color: #fff;
      height: 100%;

      .btn_box {
        margin: 0 auto;
        text-align: center;
        line-height: 66px;
        width: 550px;
        height: 100%;
        background: linear-gradient(180deg, #ffcb73 0%, #f46f02 100%);
        // box-shadow: 0px 2px 4px 0px rgba(225, 225, 225, 0.5);
        border-radius: 10px;
        font-size: 30px;
        font-weight: 600;
        color: #ffffff;
      }

      .btn_box_done {
        margin: 0 auto;
        text-align: center;
        line-height: 66px;
        width: 550px;
        height: 100%;
        border-radius: 10px;
        border: 3px solid;
        font-size: 30px;
        font-weight: 600;
        background-color: #fff;
        color: #fb8133;
      }
    }
  }

  .topImg {
    height: 258px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .body {
    width: 100%;
    padding: 0 32px;
    margin-top: 0px;
    z-index: 99;
    position: relative;
    height: 80%;
    box-sizing: border-box;

    .in_body::-webkit-scrollbar {
      display: none;
    }

    .in_body {
      width: 100%;
      height: 100%;
      border-radius: 16px;
      box-sizing: border-box;
      background-color: #fff;
      overflow: hidden;
      overflow-y: auto;

      .no_content {
        width: 398px;
        margin: 0 auto;
        margin-top: 206px;

        img {
          width: 100%;
          height: 240px;
          margin-bottom: 32px;
        }

        .message {
          text-align: center;
          font-size: 28px;
          color: rgba(0, 0, 0, 0.25);
        }
      }

      .body-center {
        width: 100%;
        border-radius: 16px;
        padding: 32px 54px 60px;
        box-sizing: border-box;
        position: relative;

        .body-center-title {
          h2 {
            text-align: center;
            margin-bottom: 30px;
            font-size: 36px;
            font-weight: 700;
            color: #000;
            line-height: 44px;
          }

          h3 {
            text-align: center;
            margin-bottom: 48px;
            font-size: 32px;
            font-weight: 700;
            color: #000;
            line-height: 44px;
          }
        }

        .body-c {
          padding-bottom: 50px;
          word-break: break-all;
          white-space: pre-wrap;
          /deep/ h2 {
            font-size: 36px;
            text-align-last: left;
          }
          /deep/ p {
            font-size: 30px;
          }
          /deep/img {
            width: 100%;
          }
          /deep/ video {
            width: 100%;
          }
        }

        .item {
          margin-bottom: 48px;
          font-size: 32px;
          font-weight: 400;
          color: #646668;
          line-height: 44px;

          .item-t {
            font-size: 32px;
            font-weight: 700;
            color: #323334;
            line-height: 44px;
            margin-bottom: 8px;
          }
        }

        .title {
          font-size: 32px;
          text-align: center;
          font-weight: bold;
          color: #2992fd;
          margin-bottom: 22px;
          line-height: 44px;

          span {
            position: relative;

            &::before {
              content: "";
              position: absolute;
              width: 150px;
              height: 4px;
              background: linear-gradient(
                270deg,
                #2992fd 0%,
                rgba(41, 146, 253, 0) 100%
              );
              border-radius: 2px;
              left: -170px;
              top: 0;
              bottom: 0;
              margin: auto;
            }

            &::after {
              content: "";
              position: absolute;
              width: 150px;
              height: 4px;
              background: linear-gradient(
                270deg,
                #2992fd 0%,
                rgba(41, 146, 253, 0) 100%
              );
              border-radius: 2px;
              right: -170px;
              top: 0;
              bottom: 0;
              margin: auto;
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}
</style>
